/**
  * Created by misha on 27.01.19.
*/

// Variables
export let serverUrl = process.env.REACT_APP_SERVER_URL + 'api/v2/';
export let regUrl = process.env.REACT_APP_SERVER_URL + 'security/register/';
export let loginUrl = process.env.REACT_APP_SERVER_URL + 'oauth/v2/token/';
export let serverImages = process.env.REACT_APP_SERVER_URL + 'uploads/photos/';
export let serverVideos = process.env.REACT_APP_SERVER_URL + 'uploads/videos/';
export let serverDocs = process.env.REACT_APP_SERVER_URL + 'uploads/docs/';

// export let serverUrl = 'https://back.spf.yourtar.ru/' + 'api/v2/';
// export let regUrl = 'https://back.spf.yourtar.ru/' + 'security/register/';
// export let loginUrl = 'https://back.spf.yourtar.ru/' + 'oauth/v2/token/';
// export let serverImages = 'https://back.spf.yourtar.ru/' + 'uploads/photos/';
// export let serverVideos = 'https://back.spf.yourtar.ru/' + 'uploads/videos/';
// export let serverDocs = 'https://back.spf.yourtar.ru/' + 'uploads/docs/';

export let clientId = process.env.REACT_APP_CLIENT_ID;
export let clientSecret = process.env.REACT_APP_CLIENT_SECRET;
