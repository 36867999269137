import { initializeApp } from 'firebase/app';
import { getMessaging, getToken as getFCMToken, onMessage as onFCMMessage } from "firebase/messaging";

import request from "./services/ajaxManager";

const firebaseConfig = {
    apiKey: "AIzaSyB21AdLDETXVWApQg_d19YKzXijU02TEek",
    authDomain: "spfinance-7f354.firebaseapp.com",
    databaseURL: "https://spfinance-7f354.firebaseio.com",
    projectId: "spfinance-7f354",
    storageBucket: "spfinance-7f354.appspot.com",
    messagingSenderId: "845940711639",
    appId: "1:845940711639:web:7513dc678831e3aaf55092",
    measurementId: "G-GRXC8B3HGW"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging();

export const getToken = (setTokenFound, token) => {

    return getFCMToken(messaging,{vapidKey: 'BE5fIlxPprBcDiwsND8ZEX5SjpYT3McctqNjYIlYkqFRM9yTc9Vl8hzgAp9VGwuvKf6LOvDZVte4dgCjRJaWXQU'}).then((currentToken) => {
        if (currentToken) {
            console.log('current token for client: ', currentToken);

            let data = new FormData();
            data.append('token', currentToken);

            request(
                'fcm/add',
                'POST',
                data,
                {
                    "YT-AUTH-TOKEN": `YourTar ${token}`
                },
                function (response) {}
            )

            setTokenFound(true);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            // shows on the UI that permission is required
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onFCMMessage(messaging, (payload) => {
            console.log('[firebase-messaging-sw.js] Received foreground message ', payload);
            resolve(payload);
        });
    });
