import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import {Breadcrumb} from 'react-bootstrap';

import request from "../../services/ajaxManager";

const InfoArticleList = (props) => {
	const [articlesList, setArticlesList] = useState([])

	useEffect(() => {
		request(
            'article/?type=info',
            'GET',
            {},
            {
                "YT-AUTH-TOKEN": `YourTar ${props.token}`
            },
            function (response) {
				setArticlesList(response.body)
            },
			function (err) {
				console.log(err);
			}
        )
	}, [])

	const deleteArticle = (id, index) => {
		request(
            'article/',
            'DELETE',
            {
				'id': id
			},
            {
                "YT-AUTH-TOKEN": `YourTar ${props.token}`
            },
            function (response) {
				let tmp = [...articlesList]
				tmp.splice(index, 1);
				setArticlesList(tmp)
            },
			function (err) {
				console.log(err);
			}
        )
	}

	const editArticle = (article) => {
		props.editArticle(article);
        props.history.push(`/menu/static/info/edit:${article.id}`)
	}

	const addArticle = () => {
        props.addArticle({title: '', content: '', infoLink: ''})
        props.history.push('/menu/static/info/edit')
    };

	return (
		<div className='container-fluid mt-3'>
			<Breadcrumb>
				<Breadcrumb.Item 
					onClick={() => props.history.push('/menu/static')}
				>
					Статическая информация
				</Breadcrumb.Item>
				<Breadcrumb.Item active>Информационные статьи</Breadcrumb.Item>
			</Breadcrumb>
			<table className='col table'>
				<thead>
					<tr>
						<th>#</th>
						<th className='align-middle'>Статья</th>
						<th className='text-right'>
							<button className='btn btn-secondary' onClick={() => addArticle()}>Добавить статью</button>
						</th>
					</tr>
				</thead>
				<tbody>
					{articlesList.map((article, index) => (
						<tr key={article.id}>
							<td className='align-middle'>{index + 1}</td>
							<td className='align-middle'>{article.title}</td>
							<td className='align-middle text-right'>
								<button className='btn btn-success mr-3' onClick={() => editArticle(article)}>Редактировать</button>
								<button className='btn btn-danger' onClick={() => deleteArticle(article.id, index)}>Удалить</button>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
};

export default connect(state => ({
    token: state.token,
}), dispatch => ({
    editArticle: (articleData) => {
        dispatch({type: 'EDIT_INFO_ARTICLE', payload: articleData})
    },
	addArticle: (articleData) => {
        dispatch({type: 'CREATE_INFO_ARTICLE', payload: articleData})
    }
}))(InfoArticleList);