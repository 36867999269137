const addZero = data => {
    return data < 10 ? `0${data}` : data;
};

export const formatData = (data) => {
    let date = new Date(Date.parse(data));
    return `${date.getFullYear()}/${addZero(date.getMonth() + 1)}/${addZero(date.getDay())}, ${addZero(date.getHours())}:${addZero(date.getMinutes())}:${addZero(date.getSeconds())}`;

    // (`${date.getFullYear()}/${date.getMonth()}/${date.getDay()}, ${date.getHours() >= 10 ? date.getHours() : '0' + date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`)
};

export const includes = (obj, value) => {
    let have = false;

    if (Array.isArray(obj)) {
        have = obj.includes(value);
    }
    else if (typeof obj === 'object') {
        have = Object.values(obj).includes(value);
    }

    return have;
};

