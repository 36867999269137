import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import request from "../../services/ajaxManager";

const Settings = (props) => {
    const [settings, setSettings] = useState([])
    const [values, setValues] = useState({})
    const [isChecked, setIsChecked] = useState(false)

    useEffect(() => {
        getUsers();
    }, [])

    const getUsers = () => {
        request(
            `settings/`,
            'GET',
            {},
            {
                "YT-AUTH-TOKEN": `YourTar ${props.token}`
            },
            function (response) {
                setSettings(response.body ?? response)
                let valTmp = {};
                response.body.map(setting => {
                    valTmp[setting.code] = setting.value;
                    if (setting.code === 'showFakeInfo') setIsChecked(setting.value === 'true')
                })
                setValues(valTmp)
            }
        )
    };

    const editSetting = (key) => {
        let data = new FormData();
        data.append('code', settings[key].code);
        data.append('value', settings[key].code === 'showFakeInfo' ? isChecked.toString() : values[settings[key].code]);

        request(
            `settings/`,
            'POST',
            data,
            {
                "YT-AUTH-TOKEN": `YourTar ${props.token}`
            },
            function (response) {
                let tmp = settings;
                tmp[key] = response.body ?? response;
                setSettings(tmp)
            },
            (err) => {
                console.log(JSON.parse(err));
            }
        )
    };

    const editMultipleEmails = () => {
        let data = new FormData();
        data.append('code', 'order_email');
        values['order_email'].map((value, index) => {
            data.append('value[' + index + ']', value);
        })

        request(
            `settings/`,
            'POST',
            data,
            {
                "YT-AUTH-TOKEN": `YourTar ${props.token}`
            },
            function (response) {
                let tmp = [...settings]
                tmp[0].value = response.body.value
                setSettings(tmp)
            },
            (err) => {
                console.log(JSON.parse(err));
            }
        )
    };

    const handleAddEmail = () => {
        let tmp = [...settings]
        if (typeof tmp[0].value === 'string') {
            let arr = []
            let arrValues = []

            arr.push(tmp[0].value)
            arr.push('')
            tmp[0].value = [...arr]

            arrValues.push(values['order_email'])
            arrValues.push('')

            let valTmp = Object.assign({}, values);
            valTmp['order_email'] = arrValues;
            setValues(valTmp)
        } else {
            tmp[0].value.push('')
        }
        setSettings(tmp)
    }

    const deleteEmail = (id) => {
        let tmpVal = Object.assign({}, values);
        const sortValues = values['order_email'].filter((value, index) => index !== id)
        let tmp = [...settings]

        tmpVal['order_email'] = sortValues;
        setValues(tmpVal)
        tmp[0].value = sortValues

        setSettings(tmp)
    }

    return (
        <div className="container-fluid">
            <h4 className="row p-3 mb-0">Настройки</h4>
            <div className='row table-responsive mb-2'>
                <table className="col table">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th className='text-left'>Название</th>
                        <th className='text-left'>Значение</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {settings.map((setting, index) => <tr key={index + 1}>
                        <td className="align-middle">{index + 1}</td>
                        <td className="align-middle text-left">{setting.name}</td>
                        <td className="align-middle text-left">
                            {typeof setting.value !== 'string' ?
                                setting.value.map((value, indexValue) =>
                                    <div className='d-flex mb-3'>
                                        <input
                                            key={indexValue}
                                            type={setting.code === 'showFakeInfo' ? 'checkbox' : 'text'}
                                            className='form-control mr-3'
                                            onChange={(e) => {
                                                let tmp = setting.value;
                                                tmp[indexValue] = e.target.value;
                                                let valTmp = Object.assign({}, values);
                                                valTmp[setting.code] = tmp;
                                                setValues(valTmp)
                                                if (setting.code === 'showFakeInfo') setIsChecked(!isChecked)
                                            }}
                                            defaultValue={value}
                                            checked={setting.code === 'showFakeInfo' && isChecked}
                                        />
                                        <button
                                            className='btn btn-danger'
                                            onClick={() => deleteEmail(indexValue)}
                                        >
                                            Удалить
                                        </button>
                                    </div>
                                ) :
                                <input
                                    type={setting.code === 'showFakeInfo' ? 'checkbox' : 'text'}
                                    className='form-control mb-3'
                                    onChange={(e) => {
                                        let tmp = Object.assign({}, values);
                                        tmp[setting.code] = e.target.value;
                                        setValues(tmp)
                                        if (setting.code === 'showFakeInfo') setIsChecked(!isChecked)
                                    }}
                                    defaultValue={setting.value}
                                    checked={setting.code === 'showFakeInfo' && isChecked}
                                />}
                            {setting.code === 'order_email' &&
                            <button
                                className='btn btn-secondary'
                                onClick={() => handleAddEmail()}
                            >
                                Добавить почту
                            </button>
                            }
                        </td>
                        <td className="align-middle">
                            <div className='float-right'>
                                <button className="btn btn-success mr-3" onClick={() => {
                                    if (setting.code === 'order_email') editMultipleEmails();
                                    else editSetting(index)
                                    //
                                }}>Сохранить
                                </button>
                            </div>
                        </td>
                    </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default connect(state => ({
    token: state.token,
}), dispatch => ({}))(Settings);
