import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import {EditorState} from 'draft-js';
import TextEditor from '../abstract/editor';
import {convertFromHTML, convertToHTML} from 'draft-convert';
import {Breadcrumb} from 'react-bootstrap';

import request from "../../services/ajaxManager";

const EditInfoArticle = ({token, article, history, isEdit}) => {
	const [editorState, setEditorState] = useState(EditorState.createEmpty())
	const [editorHtml, setEditorHtml] = useState('')
	const [articleTitle, setArticleTitle] = useState(isEdit ? article.title : '')
	const [articleLink, setArticleLink] = useState(article?.infoLink ? article.infoLink : '')

	useEffect(() => {
		const editorState = EditorState.createWithContent(convertFromHTML(article?.content ? article.content : ''))
		setEditorState(editorState)
	}, [])

	useEffect(() => {
		const html = convertToHTML(editorState.getCurrentContent());
		setEditorHtml(html)
	}, [editorState]);

	const saveData = () => {
		let data = new FormData()
		if (isEdit) {
			data.append('id', article.id)
		}
		data.append('type', 'info')
		data.append('title', articleTitle)
		if (!articleLink) {
			data.append('content', editorHtml)
		} else {
			data.append('infoLink', articleLink)
		}
		
		request(
            isEdit ? 'article/edit/' : 'article/',
            'POST',
            data,
            {
                "YT-AUTH-TOKEN": `YourTar ${token}`
            },
            function (response) {
                history.push('/menu/static/info')
            },
			function (err) {
				console.log(err);
			}
        )
	}

	return (
		<div className='container-fluid mt-3'>
			<Breadcrumb>
				<Breadcrumb.Item 
					onClick={() => history.push('/menu/static')}
				>
					Статическая информация
				</Breadcrumb.Item>
				<Breadcrumb.Item 
					onClick={() => history.push('/menu/static/info')}
				>
					Информационные статьи
				</Breadcrumb.Item>
				{isEdit ?
					<Breadcrumb.Item active>Редактирование статьи</Breadcrumb.Item> :
					<Breadcrumb.Item active>Создание статьи</Breadcrumb.Item>
				}
			</Breadcrumb>
			{isEdit ? 
				<h4 className='row ml-2'>Редактирование статьи</h4> : 
				<h4 className='row ml-2'>Создание статьи</h4>
			}
			<div className='m-2'>
				<div className='mb-3'>
					<h4>Заголовок статьи</h4>
					<input 
						className='form-control mr-3' 
						type='text' 
						placeholder='Заголовок статьи' 
						defaultValue={articleTitle}
						onChange={(event) => setArticleTitle(event.target.value)}
					/>
				</div>
				<div className='mb-3'>
					<h4>Текст статьи</h4>
					<TextEditor
						editorState={editorState}
						setEditorState={setEditorState}
					/>
				</div>
				{editorHtml === '<p></p>' && 
					<div>
						<h4>Ссылка на внешний источник</h4>
						<input
							className='form-control mr-3' 
							defaultValue={articleLink} 
							onChange={(event) => setArticleLink(event.target.value)}
							placeholder='Ссылка'
						/>
					</div>
				}
			</div>
			<button className='btn btn-success mb-5' onClick={() => saveData()}>Сохранить</button>
		</div>
	)
};

export default connect (state => ({
	token: state.token,
	article: state.data.articleToEdit,
	isEdit: state.data.isEdit,
})) (EditInfoArticle);