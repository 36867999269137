import React from 'react';
import {Editor} from 'react-draft-wysiwyg';

const TextEditor = ({editorState, setEditorState}) => {
	return (
		<Editor
			editorState={editorState}
			onEditorStateChange={setEditorState}
			className='rdw-editor-main'
			toolbar={{
				options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'history'],
				inline: {
					options: ['bold', 'italic', 'underline'],
				},
				blockType: {
					inDropdown: true
				},
				fontSize: {
					options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30],
				},
				list: {
					inDropdown: true,
				},
				textAlign: {
					inDropdown: true,
				},
			}}
		/>		
	)
};

export default TextEditor;