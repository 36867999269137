import React from 'react';

const Phone = ({phone, index, changePhone, deletePhone}) => {
    return (
        <div className='d-flex mb-3'>
            <input
                className='form-control mr-3' 
                defaultValue={phone} 
                onChange={(event) => changePhone(event.target.value, index)} 
                maxLength={12}
                placeholder='+7'
            />
            <button 
                className='btn btn-danger' 
                type='button' 
                onClick={() => deletePhone(index)}
            >
                Удалить
            </button>
        </div>
    )
}

export default Phone;
