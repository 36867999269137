import React, {Component} from 'react';
import request from "../../services/ajaxManager";
import {connect} from "react-redux";

class CreateNews extends Component {
    handleSubmit = e => {
        e.preventDefault();

        let _this = this;

        let data = {
            'title': this.titleInput.value,
            'content': this.contentInput.value,
        };

        request(
            'news/',
            'POST',
            {...data},
            {
                "YT-AUTH-TOKEN": `YourTar ${_this.props.token}`
            },
            function () {
                _this.props.history.push('/menu/news')
            }
        )
    };

    render() {
        return (
            <div className='container-fluid'>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-11 mx-auto text-center form'>
                    <h4 className="p-3 mb-0">Добавить новость</h4>
                    <div>
                        <form className="justify-content-center" onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <span>Заголовок</span>
                                <input
                                    className='form-control'
                                    name="title"
                                    ref={(input) => {
                                        this.titleInput = input
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <span>Содержание</span>
                                <input
                                    className='form-control'
                                    name="content"
                                    ref={(input) => {
                                        this.contentInput = input
                                    }}
                                />
                            </div>
                            <button type='submit' className="btn btn-success">Добавить</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    token: state.token,
}))(CreateNews);
