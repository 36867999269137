import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch, withRouter} from 'react-router-dom';
import {getToken, onMessageListener} from './firebase';
import {Toast} from 'react-bootstrap';
import {connect} from 'react-redux';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import './App.css';
import PublicLayout from './components/public/layout';
import ErrorBoundary from './components/errorBoundary';
import Modal from './components/modal';
import PrivateLayout from "./components/private/layout";

Sentry.init({
    dsn: "https://1ac955a95a51433d91811326117cca2e@o1093233.ingest.sentry.io/6637294",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

const App = (props) => { console.log(props)
    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({title: '', body: ''});
    const [isTokenFound, setTokenFound] = useState(false);

    useEffect(() => {
        if (props.token) {
            getToken(setTokenFound, props.token);

            onMessageListener().then(payload => {
                setShow(true);
                setNotification({title: payload.notification.title, body: payload.notification.body})
            }).catch(err => console.log('failed: ', err));
        }
    }, [props.token])

    return (
        <div className="App">

            <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide animation style={{
                position: 'absolute',
                top: 80,
                right: 20,
                zIndex: 10000,
            }}>
                <Toast.Header>
                    <strong className="mr-auto">{notification.title}</strong>
                    <small>just now</small>
                </Toast.Header>
                <Toast.Body>{notification.body}</Toast.Body>
            </Toast>

            <ErrorBoundary>
                <Router>
                    <Switch>
                        <Route path="/menu" component={PrivateLayout}/>
                        <Route path="/" component={PublicLayout}/>
                    </Switch>
                </Router>
                {!isTokenFound && props.token && <h1> Разрешите получение уведомлений ❗️ </h1>}
                <Modal/>
            </ErrorBoundary>
        </div>
    );
}

export default withRouter(connect(
    (state) => ({
        token: state.token,
        user: state.user,
    }),
    dispatch => ({})
)(App));
