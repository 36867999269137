import React, {useEffect, useState} from 'react'
import request from "../../services/ajaxManager";
import {connect} from "react-redux";

const BrandCatalog = (props) => {
  const [search, setSearch] = useState('')
  const [brands, setBrands] = useState([])

  useEffect(() => {
    getBrands()
  }, [])

  const getBrands = () => {
    request(
      'car-brand/',
      'GET',
      {},
      {
        'YT-AUTH-TOKEN': `YourTar ${props.token}`,
      },
      function (response) {
        setBrands(response.body ?? [])
      }
    );
  };

  const deleteBrand = (id, index) => {
    request(
      'car-brand/',
      'DELETE',
      {
        'id': id
      },
      {
        'YT-AUTH-TOKEN': `YourTar ${props.token}`
      },
      function (response) {
        let tmp = [...brands]
        tmp.splice(index, 1);
        setBrands(tmp)
      },
      function (err) {
        console.log(err);
      }
    )
  }

  const addBrand = () => {
    props.addBrand({name: '', description: ''})
    props.history.push('/menu/catalog/brands/edit')
  };

  const editBrand = (brand) => {
    props.editBrand(brand);
    props.history.push(`/menu/catalog/brands/edit:${brand.id}`)
  }

  const getModel = (brand) => {
    props.getModel(brand);
    props.history.push(`/menu/catalog/brands:${brand.id}/models`)
  }

  return (
    <div className='container-fluid'>
      <h4 className="row p-3 mb-0">Справочник авто</h4>

      <div className='row table-responsive mb-2'>
        <table className="col table">
          <thead>
            <tr>
              <th>#</th>
              <th className='text-left'>Название бренда</th>
              <th className='text-left'>Описание бренда</th>
              <th className='text-right'>
                <button className='btn btn-secondary' onClick={() => addBrand()}>Добавить бренд</button>
              </th>
            </tr>
          </thead>
          <tbody>
            {brands.map((brand, index) => (
              <tr key={index + 1}>
                <td className="align-middle">{index + 1}</td>
                <td className="align-middle text-left">{brand.name}</td>
                <td className="align-middle text-left">{brand.description}</td>
                <td className="align-middle text-right">
                  <button
                    className="btn btn-info mr-3"
                    onClick={() => getModel(brand)}
                  >
                    Просмотр
                  </button>
                  <button
                    className='btn btn-success mr-3'
                    onClick={() => editBrand(brand)}
                  >
                    Редактировать
                  </button>
                  <button
                    className='btn btn-danger'
                    onClick={() => deleteBrand(brand.id, index)}
                  >
                    Удалить
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
	)
};

export default connect(state => ({
    token: state.token,
}), dispatch => ({
  getModel: (brandData) => {
    dispatch({type: 'GET_CAR_MODEL', payload: brandData})
  },
  addBrand: (brandData) => {
    dispatch({type: 'CREATE_BRAND', payload: brandData})
  },
  editBrand: (brandData) => {
    dispatch({type: 'EDIT_BRAND', payload: brandData})
  },
})) (BrandCatalog);
