import React, {useState} from 'react'
import request from "../../services/ajaxManager";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import {Breadcrumb} from 'react-bootstrap';

const EditModel = ({ history, isEdit, model, token }) => {
  const [modelName, setModelName] = useState(isEdit ? model.name : '')
  const [modelDescription, setModelDescription] = useState(isEdit ? model.description : '')
  const {brandId} = useParams()

  const saveData = () => {
    let data = new FormData()
    if (isEdit) {
      data.append('id', model.id)
    }
    data.append('brand', brandId.replace(':', ''))
    data.append('name', modelName)
    data.append('description', modelDescription)

    request(
      isEdit ? 'car-model/update' : 'car-model/',
      'POST',
      data,
      {
        'YT-AUTH-TOKEN': `YourTar ${token}`
      },
      function (response) {
        history.goBack()
      },
      function (err) {
        console.log(err);
      }
    )
  }

  return (
    <div className='container-fluid mt-3'>
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={() => history.push('/menu/catalog/brands')}
        >
          Справочник авто
        </Breadcrumb.Item>
        <Breadcrumb.Item
            onClick={() => history.goBack()}
        >
          Список моделей
        </Breadcrumb.Item>
        {isEdit ?
          <Breadcrumb.Item active>Редактирование модели</Breadcrumb.Item> :
          <Breadcrumb.Item active>Создание модели</Breadcrumb.Item>
        }
      </Breadcrumb>
      {isEdit ?
        <h4 className='row ml-2'>Редактирование модели</h4> :
        <h4 className='row ml-2'>Создание модели</h4>
      }
      <div className='m-2'>
        <div className='mb-3'>
          <h4>Наименование модели</h4>
          <input
            className='form-control mr-3'
            type='text'
            placeholder='Наименование модели'
            defaultValue={modelName}
            onChange={(event) => setModelName(event.target.value)}
          />
        </div>
        <div className='mb-3'>
          <h4>Описание модели</h4>
          <textarea
            defaultValue={modelDescription}
            placeholder='Описание модели'
            style={{width: '100%'}}
            onChange={(e) => setModelDescription(e.target.value)}
          />
        </div>
      </div>

      <button className='btn btn-success mb-5' onClick={() => saveData()}>Сохранить</button>
    </div>
  )
};

export default connect(state => ({
  token: state.token,
  model: state.data.modelToEdit,
  isEdit: state.data.isEdit,
})) (EditModel);
