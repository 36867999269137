import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {includes} from "../../services/utils";

class UserMenu extends Component {
    render() {
        return (
            <div className='container-fluid'>
                <h4 className="row p-3 mb-0">Панель Администрирования</h4>
                <ul className='row list-group'>
                    {includes(this.props.user.roles,'ROLE_ADMIN') && <li className='list-group-item text-left'>
                        <Link to='/menu/messages'>
                            Управление сообщениями поддержки
                        </Link>
                    </li>}
                    {includes(this.props.user.roles,'ROLE_ADMIN') && <li className='list-group-item text-left'>
                        <Link to='/menu/offices'>
                                Управление офисами
                        </Link>
                    </li>}
                    <li className='list-group-item text-left'>
                        <Link to='/menu/loans'>
                            Управление заявками на займ
                        </Link>
                    </li>
                    {includes(this.props.user.roles, 'ROLE_ADMIN') && <li className='list-group-item text-left'>
                        <Link to='/menu/users'>
                            Управление пользователями
                        </Link>
                    </li>}
                    {includes(this.props.user.roles, 'ROLE_ADMIN') && <li className='list-group-item text-left'>
                        <Link to='/menu/logs'>
                            Логи
                        </Link>
                    </li>}
                    {includes(this.props.user.roles, 'ROLE_ADMIN') && <li className='list-group-item text-left'>
                        <Link to='/menu/static'>
                            Статическая информация
                        </Link>
                    </li>}
                    {includes(this.props.user.roles, 'ROLE_ADMIN') && <li className='list-group-item text-left'>
                        <Link to='/menu/catalog/brands'>
                            Справочник авто
                        </Link>
                    </li>}
                    {includes(this.props.user.roles, 'ROLE_ADMIN') && <li className='list-group-item text-left'>
                        <Link to='/menu/settings'>
                            Настройки
                        </Link>
                    </li>}
                </ul>
            </div>
        );
    }
}

export default connect(state => ({
    token: state.token,
    user: state.user,
}), dispatch => ({}))(UserMenu);
