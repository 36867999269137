import React, {useState, useEffect} from 'react';
import {EditorState} from 'draft-js';
import TextEditor from '../abstract/editor';
import {convertFromHTML, convertToHTML} from 'draft-convert';
import {Breadcrumb} from 'react-bootstrap';
import {connect} from "react-redux";

import request from "../../services/ajaxManager";

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../styles/editor.css'

const EditPayment = ({token, history}) => {
	const [editorState, setEditorState] = useState(EditorState.createEmpty())
	const [editorHtml, setEditorHtml] = useState()
	const [paymentTitle, setPaymentTitle] = useState('')
	const [paymentLinks, setPaymentLinks] = useState([])

	useEffect(() => {
		request(
            'article/?type=payment',
            'GET',
            {},
            {
                "YT-AUTH-TOKEN": `YourTar ${token}`
            },
            function (response) {
				const editorState = EditorState.createWithContent(convertFromHTML(response.body[0].content));
				setPaymentTitle(response.body[0].title)
				setPaymentLinks(response.body[0].links)
				setEditorState(editorState)
            },
			function (err) {
				console.log(err);
			}
        )
	}, []);

	useEffect(() => {
		const html = convertToHTML(editorState.getCurrentContent());
		setEditorHtml(html)
	}, [editorState]);

	const saveData = () => {
		let data = new FormData()
		data.append('type', 'payment')
		data.append('title', paymentTitle)
		data.append('content', editorHtml)
		paymentLinks.map((link, index) => {
			data.append('links[' + index + ']', link)
		})

		request(
            'article/',
            'POST',
            data,
            {
                "YT-AUTH-TOKEN": `YourTar ${token}`
            },
            function (response) {
                const editorState = EditorState.createWithContent(convertFromHTML(response.body.content));
				setPaymentTitle(response.body.title)
				setEditorState(editorState)
				history.push('/menu/static')
            },
			function (err) {
				console.log(err);
			}
        )
	}

	const addLink = () => {
		setPaymentLinks(prevState => ([...prevState, '']))
	}

	const changeLink = (link, index) => {
		let tmp = [...paymentLinks]
        tmp.splice(index, 1, link)
		setPaymentLinks(tmp)
	}

	const deleteLink = (index) => {
		let tmp = [...paymentLinks]
		tmp.splice(index, 1);
		setPaymentLinks(tmp)
	}

	return (
		<div className='container-fluid mt-3'>
			<Breadcrumb>
				<Breadcrumb.Item 
					onClick={() => history.push('/menu/static')}
				>
					Статическая информация
				</Breadcrumb.Item>
				<Breadcrumb.Item active>Страница оплаты</Breadcrumb.Item>
			</Breadcrumb>
			<h4 className='row ml-2'>Страница редактирования оплаты</h4>
			<div className='m-2'>
				<div className='mb-3'>
					<h4>Заголовок статьи</h4>
					<input 
						className='form-control mr-3' 
						type='text' 
						placeholder='Заголовок статьи' 
						defaultValue={paymentTitle}
						onChange={(event) => setPaymentTitle(event.target.value)}
					/>
				</div>
				<div className='mb-3'>
					<h4>Текст статьи</h4>
					<TextEditor
						editorState={editorState}
						setEditorState={setEditorState}
					/>
				</div>
				<div>
					<h4>Ссылки</h4>
					<div className='d-flex align-items-start'>
						<button 
							type='button' 
							className='btn btn-secondary mb-3' 
							onClick={() => addLink()}
						>
							Добавить
						</button>
					</div>
					{paymentLinks.map((link, index) => (
						<div className='d-flex mb-3' key={index + Date.now()}>
							<input
								className='form-control mr-3' 
								defaultValue={link} 
								onChange={(event) => changeLink(event.target.value, index)}
								placeholder='Ссылка'
							/>
							<button 
								className='btn btn-danger' 
								type='button' 
								onClick={() => deleteLink(index)}
							>
								Удалить
							</button>
						</div>
					))}
				</div>
			</div>
			<button className='btn btn-success mb-5' onClick={() => saveData()}>Сохранить</button>
		</div>
	)
};

export default connect (state => ({
    token: state.token
})) (EditPayment);