import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import Logo from '../images/spfinans_logo.svg';
import {includes} from "../services/utils";

const items = [
    {
        path: '/menu',
        title: 'Меню',
        admin: false,
    },
    // {
    //     path: '/menu/news',
    //     title: 'Новости'
    // },
    {
        path: '/menu/messages',
        title: 'Сообщения',
        admin: true,
    },
    {
        path: '/menu/offices',
        title: 'Офисы',
        admin: true,
    },
    {
        path: '/menu/loans',
        title: 'Заявки на займы',
        admin: false,
    },
    {
        path: '/menu/users',
        title: 'Пользователи',
        admin: true,
    },
    {
        path: '/menu/logs',
        title: 'Логи',
        admin: true,
    },
    {
        path: '/menu/static',
        title: 'Статическая информация',
        admin: true,
    },
    {
        path: '/menu/catalog/brands',
        title: 'Справочник авто',
        admin: true,
    },
    {
        path: '/menu/settings',
        title: 'Настройки',
        admin: true,
    },
];

class Menu extends Component {
    state = {
        items
    };


    logOut = () => {
        this.props.logOut();
    };


    render() {
        const { enabled } = this.props;
        return (
            <nav className="navbar navbar-dark bg-dark navbar-expand-lg">
                <Link className="navbar-brand" to="/">
                    <img alt='Logo' src={Logo}/>
                </Link>

                {enabled && <>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            {this.state.items.map((item, key) => {
                                if (item.admin && !includes(this.props.user.roles, 'ROLE_ADMIN')) {
                                    return null;
                                }

                                return (
                                    <li key={key}
                                        className={"nav-item " + (item.path === window.location.pathname ? 'active ' : '')}>
                                        <Link className="nav-link" to={item.path}>{item.title}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                        <button className="btn btn-danger" onClick={() => this.logOut()}>Выход</button>
                </div>
                </>}
            </nav>
        );
    }
}

export default connect (state => ({
    user: state.user,
}), dispatch => ({
    logOut: () => {
        dispatch({type: 'DELETE_TOKEN'})
    }
})) (Menu);
