import React, {Component} from 'react';

class Index extends Component {
    render() {
        return (
            <div>
                <h1></h1>
            </div>
        );
    }
}

export default Index;
