import React, {useState} from 'react'
import request from "../../services/ajaxManager";
import {connect} from "react-redux";
import {Breadcrumb} from 'react-bootstrap'

const EditBrand = ({ history, isEdit, brand, token }) => {
  const [brandName, setBrandName] = useState(isEdit ? brand.name : '')
  const [brandDescription, setBrandDescription] = useState(isEdit ? brand.description : '')

  const saveData = () => {
    let data = new FormData()
    if (isEdit) {
      data.append('id', brand.id)
    }
    data.append('name', brandName)
    data.append('description', brandDescription)

    request(
      isEdit ? 'car-brand/update' : 'car-brand/',
      'POST',
      data,
      {
        'YT-AUTH-TOKEN': `YourTar ${token}`
      },
      function (response) {
        history.push('/menu/catalog/brands')
      },
      function (err) {
        console.log(err);
      }
    )
  }

  return (
    <div className='container-fluid mt-3'>
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={() => history.push('/menu/catalog/brands')}
        >
          Справочник авто
        </Breadcrumb.Item>
        {isEdit ?
          <Breadcrumb.Item active>Редактирование бренда</Breadcrumb.Item> :
          <Breadcrumb.Item active>Создание бренда</Breadcrumb.Item>
        }
      </Breadcrumb>
      {isEdit ?
        <h4 className='row ml-2'>Редактирование бренда</h4> :
        <h4 className='row ml-2'>Создание бренда</h4>
      }
      <div className='m-2'>
        <div className='mb-3'>
          <h4>Наименование бренда</h4>
          <input
            className='form-control mr-3'
            type='text'
            placeholder='Наименование бренда'
            defaultValue={brandName}
            onChange={(event) => setBrandName(event.target.value)}
          />
        </div>
        <div className='mb-3'>
          <h4>Описание бренда</h4>
          <textarea
            defaultValue={brandDescription}
            placeholder='Описание бренда'
            style={{width: '100%'}}
            onChange={(e) => setBrandDescription(e.target.value)}
          />
        </div>
      </div>

      <button className='btn btn-success mb-5' onClick={() => saveData()}>Сохранить</button>
    </div>
  )
};

export default connect(state => ({
  token: state.token,
  brand: state.data.brandToEdit,
  isEdit: state.data.isEdit,
})) (EditBrand);
