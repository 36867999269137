const initialState = {
    news: [],
    newsToEdit: {title: '', content: ''},
    officeToEdit: {address: '', email: '', phones: []},
    isEdit: false,
};

export default function data(state = initialState, action) {
    switch (action.type) {
        case 'GET_NEWS_SUCCESS':
            return {news: [...state.news, ...action.payload]};
        case 'GET_CAR_MODEL':
            return {...state, brand: {...action.payload}};
        case 'EDIT_NEWS':
            return {...state, newsToEdit: {...action.payload}};
        case 'EDIT_OFFICE':
            return {...state, officeToEdit: {...action.payload}, isEdit: true};
        case 'EDIT_INFO_ARTICLE':
            return {...state, articleToEdit: {...action.payload}, isEdit: true};
        case 'EDIT_BRAND':
            return {...state, brandToEdit: {...action.payload}, isEdit: true};
        case 'EDIT_MODEL':
            return {...state, modelToEdit: {...action.payload}, isEdit: true};
        case 'CREATE_INFO_ARTICLE':
            return {...state, articleToEdit: {...action.payload}, isEdit: false};
        case 'CREATE_BRAND':
            return {...state, brandToEdit: {...action.payload}, isEdit: false};
        case 'CREATE_MODEL':
            return {...state, modelToEdit: {...action.payload}, isEdit: false};
        default:
            return {...state};
    }
}
