import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Player} from 'video-react';
import 'video-react/dist/video-react.css';
import ModalImage from "react-modal-image";

import request from "../../services/ajaxManager";
import login from "../public/sign_action/login";

class Logs extends Component {
    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);

        let obj;
        if (props.location.search && props.location.search.length > 0) {
            let search = props.location.search.substring(1);
            obj = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
                return key === "" ? value : decodeURIComponent(value)
            })
        }

        this.state = {
            page: 0,
            logs: [],
            image: '',
            open: false,
            managers: [],
            search: obj && obj.search ? obj.search : '',
        };
    }

    hiddenFileInput;



    componentDidMount() {
        this.getNews();
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight) {
            this.getNews();
        }
    };

    getNews(reload = false) {
        const page = reload ? 0 : this.state.page;
        const _this = this;

        request(
            `log/?page=` + page +
                (this.state.search && this.state.search > 0 ? '&search='  + this.state.search : ''),
            'GET',
            {},
            {
                "YT-AUTH-TOKEN": `YourTar ${this.props.token}`
            },
            function (response) {
                let list = reload ? [] : _this.state.logs;
                _this.setState({
                    logs: list.concat(response.body ?? response),
                    page: page + 1,
                });
            },
            function (err) {
                console.log(err)
            }
        )
    };

    submit(e) {
        e.preventDefault();

        if (this.state.page !== 0)
            this.setState({page: 0}, () => this.getNews(true))
        else this.getNews(true);
    };

    render() {
        const {logs} = this.state;
        return (
            <div className="container-fluid">
                <h4 className="row p-3 mb-0">Логи</h4>
                <form onSubmit={this.submit}>
                    <div className="form-row">
                        <div className="col">
                            <input type="text" className="form-control " defaultValue={this.state.search}
                                   placeholder="Поиск ..." onChange={(e) => {
                                       this.setState({search: e.target.value});
                            }}/>
                        </div>
                        <div className="col">
                            <button type="submit" onClick={this.submit}
                                    className="btn btn-success mb-6">Найти</button>
                        </div>
                    </div>
                </form>
                <br/>
                <div className='row table-responsive mb-2'>
                    <table className="col table">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th className='text-left'>Дата</th>
                            <th className='text-left'>Действие</th>
                            <th className='text-left'>ID инициатора</th>
                        </tr>
                        </thead>
                        <tbody>
                        {logs.map((post, index) => {
                            let date = new Date(post.moment);
                            return <tr key={index + 1}>
                                <td className="align-middle">{index + 1}</td>
                                <td className="align-middle text-left">
                                    {date.getHours()}:{date.getMinutes()} {date.getDate()}-{date.getMonth() + 1}-{date.getFullYear()}
                                </td>
                                <td className="align-middle text-left">
                                    {post.content}
                                </td>
                                <td className="align-middle text-left">
                                    {post.user.id}
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    token: state.token,
    user: state.user,
}), dispatch => ({}))(Logs);
