import React, { Component } from 'react';
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import UserMenu from "./userMenu";
import News from "./news";
import LoanOrders from "./loanOrders";
import CreateNews from "./createNews";
import EditNews from "./editNews";
import Messages from "./messages";
import Offices from "./offices";
import EditOffice from "./editOffice";
import Menu from "../menu";
import ChangePassword from "./changePassword";
import Settings from "./settings";
import Users from "./users";
import Logs from "./logs";
import StaticInfo from './staticInfo';
import EditPayment from './editPayment';
import InfoArticleList from './infoArticleList';
import EditInfoArticle from './editInfoArticle';
import BrandCatalog from './brandCatalog'
import ModelCatalog from './modelCatalog'
import EditBrand from './editBrand'
import EditModel from './editModel'

class PrivateLayout extends Component {
    componentDidMount() {
        this.autoLogOut();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.autoLogOut();
    }

    autoLogOut = () => {
        const {token, history, user} = this.props;
        if(token === false || !user) {
            this.props.logOut();
            history.push('/');
        }
    };

    render() {
        return (
            <div>
                <Menu { ...this.props } enabled={true}/>
                <Switch>
                    <Route exact path='/menu' component={UserMenu}/>
                    <Route exact path='/menu/news/create' component={CreateNews}/>
                    <Route exact path='/menu/news/:postId' component={EditNews}/>
                    <Route exact path='/menu/news' component={News}/>
                    <Route exact path='/menu/loans' component={LoanOrders}/>
                    <Route exact path='/menu/messages' component={Messages}/>
                    <Route exact path='/menu/offices' component={Offices}/>
                    <Route exact path='/menu/offices/:officeId' component={EditOffice}/>
                    <Route exact path='/menu/password' component={ChangePassword}/>
                    <Route exact path='/menu/settings' component={Settings}/>
                    <Route exact path='/menu/users' component={Users}/>
                    <Route exact path='/menu/logs' component={Logs}/>
                    <Route exact path='/menu/static' component={StaticInfo}/>
                    <Route exact path='/menu/static/payment' component={EditPayment}/>
                    <Route exact path='/menu/static/info' component={InfoArticleList}/>
                    <Route exact path='/menu/static/info/edit' component={EditInfoArticle}/>
                    <Route exact path='/menu/static/info/edit:infoId' component={EditInfoArticle}/>
                    <Route exact path='/menu/catalog/brands' component={BrandCatalog}/>
                    <Route exact path='/menu/catalog/brands/edit' component={EditBrand}/>
                    <Route exact path='/menu/catalog/brands/edit:brandId' component={EditBrand}/>
                    <Route exact path='/menu/catalog/brands:brandId/models' component={ModelCatalog}/>
                    <Route exact path='/menu/catalog/brands:brandId/models/edit' component={EditModel}/>
                    <Route exact path='/menu/catalog/brands:brandId/models/edit:modelId' component={EditModel}/>
                </Switch>
            </div>
        );
    }
}

export default withRouter(connect(
    (state, ownProps) => ({
        token: state.token,
        user: state.user,
    }),
    dispatch => ({
        logOut: () => {
            dispatch({type: 'DELETE_TOKEN'})
        }
    })
)(PrivateLayout));
