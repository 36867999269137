import React, {useEffect, useState} from 'react'
import request from "../../services/ajaxManager";
import {connect} from "react-redux";
import {Breadcrumb} from 'react-bootstrap';
import {useParams} from 'react-router-dom';

const ModelCatalog = (props) => {
  const [models, setModels] = useState([])
  const {brandId} = useParams()

  useEffect(() => {
    getModels()
  }, [])

  const getModels = () => {
    request(
      `car-model/?brand=${brandId.replace(':', '')}`,
      'GET',
      {},
      {
        'YT-AUTH-TOKEN': `YourTar ${props.token}`,
      },
      function (response) {
        setModels(response.body ?? [])
      }
    );
  };

  const deleteModel = (id, index) => {
    request(
      'car-model/',
      'DELETE',
      {
        'id': id
      },
      {
        'YT-AUTH-TOKEN': `YourTar ${props.token}`
      },
      function (response) {
        let tmp = [...models]
        tmp.splice(index, 1);
        setModels(tmp)
      },
      function (err) {
        console.log(err);
      }
    )
  }

  const addModel = () => {
    props.addModel({name: '', description: ''})
    props.history.push(`/menu/catalog/brands:${props.brand.id}/models/edit`)
  };

  const editModel = (model) => {
    props.editModel(model);
    props.history.push(`/menu/catalog/brands:${props.brand.id}/models/edit:${model.id}`)
  }

  return (
    <div className='container-fluid mt-3'>
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={() => props.history.push('/menu/catalog/brands')}
        >
          Справочник авто
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Список моделей</Breadcrumb.Item>
      </Breadcrumb>
      <h4 className="row p-3 mb-0">Модели данного бренда</h4>
      <div className='row table-responsive mb-2'>
        <table className="col table">
          <thead>
          <tr>
            <th>#</th>
            <th className='text-left'>Название модели</th>
            <th className='text-left'>Описание модели</th>
            <th className='text-right'>
              <button className='btn btn-secondary' onClick={() => addModel()}>Добавить модель</button>
            </th>
          </tr>
          </thead>
          <tbody>
          {models.map((model, index) => (
            <tr key={index + 1}>
              <td className="align-middle">{index + 1}</td>
              <td className="align-middle text-left">{model.name}</td>
              <td className="align-middle text-left">{model.description}</td>
              <td className="align-middle text-right">
                <button
                  className='btn btn-success mr-3'
                  onClick={() => editModel(model)}
                >
                  Редактировать
                </button>
                <button
                  className='btn btn-danger'
                  onClick={() => deleteModel(model.id, index)}
                >
                  Удалить
                </button>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  )
};

export default connect(state => ({
  token: state.token,
  brand: state.data.brand,
}), dispatch => ({
  addModel: (modelData) => {
    dispatch({type: 'CREATE_MODEL', payload: modelData})
  },
  editModel: (modelData) => {
    dispatch({type: 'EDIT_MODEL', payload: modelData})
  },
})) (ModelCatalog);
