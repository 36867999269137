import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Modal} from 'react-bootstrap';
import ModalImage from "react-modal-image";
import {Player} from 'video-react';
import {serverImages, serverVideos} from '../../services/parameters';
import {includes} from "../../services/utils";

const LoanModal = (props) => {
    const [post, setPost] = useState(props.data);
    const [date, setDate] = useState(new Date(props.data.moment));

    useEffect(() => {
        setPost(props.data);
        setDate(props.data.moment.split('T')[0].split('-'))
    }, [props.data, props.updated])

    return (
        <Modal show={props.isShow}
               onHide={props.onHideModal}
               size="xl"
               aria-labelledby='custom-modal-styling-title'>
            <Modal.Header closeButton>
                <Modal.Title id='custom-modal-styling-title'>
                    Подробная информация о заявке
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {post.mark && post.customModel ?
                    <p><b>{post.mark} {post.customModel} {post.releaseYear ?? ''}</b></p> : ''}
                {post.model && post.model.brand ?
                    <p><b>{post.model.brand.name} {post.model.name} {post.releaseYear ?? ''}</b></p> : ''}
                {post.vin ? <p>VIN: <b>{post.vin}</b></p> : ''}
                {post.mileage ? <p>Пробег: {post.mileage}</p> : ''}
                {post.card && <p>Высылать {post.card && 'на карту ' + post.card}</p>}
                {post.phone && <p>Высылать {post.phone && 'по телефону ' + post.phone}</p>}
                {post.requisites && Object.keys(post.requisites).length > 0 &&
                <p>Высылать на реквизиты:
                    {post.requisites.surname} {post.requisites.name} {post.requisites.middle_name};
                    БИК банка - {post.requisites.bik}; номер счета - {post.requisites.number};
                    ИНН - {post.requisites.inn}
                </p>}
                <br/>
                Запрашиваемая сумма {post.amount} p.
                <br/>
                Дата изменения: {(date[2] ?? 'xx').toString()}.
                {(date[1] ?? 'xx').toString()}.{(date[0] ?? 'xxxx').toString()}

                <div>
                    <label>Одобренная сумма:
                        <i>{post.allowedAmount ? post.allowedAmount + ' p.' : 'не определена'}</i>
                    </label>
                    {!post.done &&
                    <>
                        <input
                            className={'form-control mb-2'}
                            defaultValue={post.allowedAmount ?? 0}
                            onChange={(e) => {
                                console.log(e.target.value)
                                props.handleAllowedAmount(e.target.value, props.index)
                            }
                            }
                            disabled={post.done}
                            type={'number'}/>
                        <button
                            disabled={!post.allowedAmountChange}
                            className={'btn btn-primary'}
                            onClick={(e) => props.customizeLoan(e, post.id)}
                        >
                            Сохранить одобренную сумму
                        </button>
                    </>}
                </div>
                <br/>

                <div
                    className={'borderBlock'}
                    style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <label>Сообщение пользователю:</label>
                    <>
						<textarea
                            defaultValue={post.message ?? ''}
                            style={{width: '100%'}}
                            onChange={(e) => props.handleLoanMessage(e.target.value, props.index)}
                        />
                        <br/>
                        <button
                            className={'btn btn-primary'}
                            onClick={() => props.customizeLoanMessage(post.id)}
                        >
                            Сохранить сообщение
                        </button>
                    </>
                </div>
                <br/>

                {includes(props.user.roles, 'ROLE_ADMIN') &&
                <div style={{display: 'flex'}}>
                    <Link to={'/menu/logs?search=' + encodeURI(' ' + post.id + ' ')}>
                        <button className={'btn btn-info'}>Посмотреть историю</button>
                    </Link>
                    {post.geo && <a
                        href={'http://www.google.com/maps/place/' +
                        post.geo.country + ',' +
                        post.geo.city + ',' +
                        post.geo.street + ',' +
                        post.geo.name}
                        target={'__blank'}
                    >
                        <button className={'btn btn-info'} style={{marginLeft: 10}}>
                            Посмотреть на карте
                        </button>
                    </a>}
                </div>
                }
                <br/>

                {typeof post.video === 'string' ?
                    <div className='w-25'>
                        <Player>
                            <source src={serverVideos + post.video}/>
                        </Player>
                    </div> :
                    post.video.map(video => (
                        <div className='d-flex w-25'>
                            <Player className='mr-2'>
                                <source src={serverVideos + video}/>
                            </Player>
                        </div>
                    ))
                }

                <div className={'d-flex mt-2'}>
                    {post.photos.map((item, keyIm) => {
                        return <ModalImage large={serverImages + item}
                                           small={serverImages + item}
                                           className={'imageRow smallImage mr-2'} key={keyIm} alt={item}/>;
                    })}
                </div>

                <div className="form-group">
                    <label htmlFor={post.id + "_exampleFormControlSelect1"}>
                        Номер договора (ID2)
                    </label>
                    <input
                        className={'form-control'}
                        defaultValue={post.customId}
                        onChange={(e) => props.handleLoanContract(e.target.value, props.index)}
                        disabled={post.done}
                    />
                </div>

                {(!post.customId || !(post.done !== undefined && post.done !== null)) &&
                <button
                    className={'btn btn-primary'}
                    onClick={(e) => {
                        props.saveCustomLoanId(e, post.id);
                    }}
                >
                    Сохранить номер договора
                </button>}

                <div className="form-group">
                    <label htmlFor={post.id + "_exampleFormControlSelect1"}>
                        Выбор статуса
                    </label>
                    <select className="form-control" id={post.id + "_exampleFormControlSelect1"}
                            onChange={(e) => {
                                if (e.target.value !== null) {
                                    props.doneLoan(null, post.id, e.target.value)
                                }
                            }}
                            defaultValue={
                                post.status === 'pending' || post.status === null
                                    ? null : post.status === 'rejected'
                                        ? 0 : post.status === 'approved'
                                            ? 1 : post.status === 'transfer_made'
                                                ? 2 : post.status === 'in_office'
                                                    ? 3 : post.status === 'finished'
                                                        ? 4 : post.status === 'signed'
                                                            ? 5 : post.status === 'online'
                                                                ? 6 : 7
                            }>
                        <option value={null}>На рассмотрении</option>
                        <option value={0}>Отказано</option>
                        <option value={1} disabled={!post.allowedAmount}>
                            Одобрено
                        </option>
                        <option value={3}>
                            Выдача в офисе
                        </option>
                        <option value={6}>
                            Выдача онлайн
                        </option>
                        <option value={7} disabled={true}>
                            Требуются документы
                        </option>
                        <option value={2}
                                disabled={!post.customId || post.customId.length < 0 || post.status !== 'in_office'}>
                            Произведена выдача
                        </option>
                        <option value={5} disabled>
                            Подписана
                        </option>
                        <option value={4} disabled={!post.customId || post.customId.length < 0}>
                            Завершена
                        </option>
                    </select>
                </div>

                <button
                    className="btn btn-success mr-3"
                    disabled={post.done === false || post.inOffice === true || post.issued === true}
                    onClick={() =>
                        props.downloadFiles(post)
                    }
                >
                    Загрузить файлы
                </button>
                {post.docs.map((doc, key2) => {
                    return <p key={key2}>
                        <a>{doc}</a>
                        <button className="btn btn-danger btn-sm"
                                onClick={() => props.removeDoc(doc, key2, props.index)}>Удалить
                        </button>
                    </p>;
                })}

                {includes(props.user.roles, 'ROLE_ADMIN') &&
                <button
                    className="btn btn-danger"
                    onClick={() => props.deleteLoan(props.index)}
                >
                    Удалить займ
                </button>
                }
            </Modal.Body>
        </Modal>
    )
};

export default LoanModal;
