import React, {Component} from 'react';
import {connect} from 'react-redux';
import 'video-react/dist/video-react.css';
import ModalImage from "react-modal-image";

import request from "../../services/ajaxManager";
import { serverImages } from '../../services/parameters';
import {includes} from "../../services/utils";
import LoanModal from '../abstract/loanModal';

class LoanOrders extends Component {
    constructor(props) {
        super(props);

        this.hiddenFileInput = React.createRef(null);
    }

    hiddenFileInput;
    state = {
        page: 0,
        loans: [],
        image: '',
        open: false,
        managers: [],
        isShow: false,
        currentLoan: {},
        currentLoanIndex: 0,
    };


    componentDidMount() {
        this.getNews();
        this.getManagers();
        // window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        // window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        // const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        // const body = document.body;
        // const html = document.documentElement;
        // const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        // const windowBottom = windowHeight + window.pageYOffset;
        // if (windowBottom >= docHeight) {
        //     this.getNews();
        // }
    };

    onHideModal = () => {
        this.setState({isShow: false})
    }

    handleAllowedAmount = (value, index) => {
        console.log('change amount: ' + value)
        let tmp = [...this.state.loans];
        tmp[index].allowedAmount = parseInt(value);
        tmp[index].allowedAmountChange = true;
        this.setState({loans: tmp, updated: Date.now()});
    }

    handleLoanMessage = (value, index) => {
        let tmp = [...this.state.loans];
        tmp[index].message = value;
        this.setState({loans: tmp, updated: Date.now()});
    }

    handleLoanContract = (value, index) => {
        let tmp = [...this.state.loans];
        tmp[index].inputCustomId = value;
        this.setState({loans: tmp, updated: Date.now()});
    }

    downloadFiles = (post) => {
        this.setState({currentId: post.id}, () =>
        this.hiddenFileInput.current.click());
        this.onHideModal()
    }

    deleteLoan = (id) => {
        const _this = this;

        request(
            'loan/',
            'DELETE',
            {
                'id': this.state.loans[id].id,
            },
            {
                "YT-AUTH-TOKEN": `YourTar ${this.props.token}`
            },
            function (response) {
                let arr = _this.state.loans;
                arr.splice(id, 1);
                _this.setState({loans: arr, updated: Date.now(), isShow: false});
                _this.getNews(true);
            }
        )
    };

    doneLoan = (event = null, id, result) => {
        const _this = this;

        let data = new FormData()

        data.append('id', id);
        if (event) {
            for (let i = 0; i < event.target.files.length; i++) {
                data.append('files[' + i + ']', event.target.files[i]);
            }
        } else {
            switch (result) {
                case 0:
                case '0':
                    data.append('status', 'rejected');
                    if (this.state.loans.find(item => item.id === id).customId) data.append('customId', this.state.loans.find(item => item.id === id).customId);
                    break;
                case 1:
                case '1':
                    data.append('status', 'approved');
                    data.append('customId', this.state.loans.find(item => item.id === id).customId);
                    break;
                case 2:
                case '2':
                    data.append('status', 'transfer_made');
                    break;
                case 3:
                case '3':
                    data.append('status', 'in_office');
                    break;
                case 4:
                case '4':
                    data.append('status', 'finished')
                    break;
                case 6:
                case '6':
                    data.append('status', 'online')
                    break;
                default:
                    data.append('status', 'pending')
                    break;
            }
        }

        request(
            'loan/change/status',
            'POST',
            data,
            {
                "YT-AUTH-TOKEN": `YourTar ${this.props.token}`
            },
            function (response) {
                _this.getNews(true);
                _this.setState({currentId: null});
            }
        )
    };

    saveCustomLoanId = (event = null, id) => {
        const _this = this;

        let data = new FormData()

        data.append('id', id);
        data.append('customId', this.state.loans.find(item => item.id === id).inputCustomId);

        request(
            'loan/customId',
            'POST',
            data,
            {
                "YT-AUTH-TOKEN": `YourTar ${this.props.token}`
            },
            function (response) {
                _this.getNews(true);

                let tmp = [..._this.state.loans]
                tmp[tmp.findIndex(item => item.id === response.body.id)] = response.body;
                _this.setState({loans: tmp})
            }
        )
    };

    customizeLoan = (event = null, id) => {
        let _this = this;

        let data = new FormData()

        data.append('id', id);
        data.append('allowed_amount', this.state.loans.find(item => item.id === id).allowedAmount);

        request(
            'loan/change',
            'POST',
            data,
            {
                "YT-AUTH-TOKEN": `YourTar ${this.props.token}`
            },
            function (response) {
                let tmp = Array.from(_this.state.loans);
                tmp[tmp.findIndex(loan => loan.id === id)].allowedAmountChange = false;
                _this.setState({loans: tmp, updated: Date.now()});
            }
        )
    };

    customizeLoanMessage = (id) => {
        let _this = this;

        let data = new FormData()

        data.append('id', id);
        data.append('message', _this.state.loans.find(item => item.id === id).message);

        request(
            'loan/change/message',
            'POST',
            data,
            {
                "YT-AUTH-TOKEN": `YourTar ${_this.props.token}`
            },
            function(response) {
                console.log(response);
            },
            function(err) {
                console.log(err);
            }
        )
    };

    getNews(reload = false) {
        const page = reload ? 0 : this.state.page;
        const _this = this;

        request(
            `loan/${!includes(this.props.user.roles, 'ROLE_ADMIN') ? 'manager' : ''}${page === 0 ? '' : '?page=' + page}`,
            'GET',
            {},
            {
                "YT-AUTH-TOKEN": `YourTar ${this.props.token}`
            },
            function (response) {
                let list = reload ? [] : _this.state.loans;
                _this.setState({
                    loans: list.concat(response.body ?? response),
                    page: page + 1,
                    updated: Date.now(),
                });
            },
            function (err) {
                console.log(err)
            }
        )
    };

    getManagers() {
        const _this = this;

        request(
            `user/managers`,
            'GET',
            {},
            {
                "YT-AUTH-TOKEN": `YourTar ${this.props.token}`
            },
            function (response) {
                _this.setState({
                    managers: response.body ?? response,
                });
            },
            function (err) {
                console.log(err)
            }
        )
    };

    setManagers(manager, loan) {
        let data = new FormData(), _this = this;
        data.append('manager', manager);
        data.append('loan', this.state.loans[loan].id);

        request(
            `loan/manager`,
            'POST',
            data,
            {
                "YT-AUTH-TOKEN": `YourTar ${this.props.token}`
            },
            function (response) {
                let tmp = [..._this.state.loans]
                tmp[loan] = response.body;
                _this.setState({loans: tmp})
            },
            function (err) {
                console.log(err)
            }
        )
    };

    removeDoc = (name, keyDoc, keyPost) => {
        let data = {
            file: name,
            id: this.state.loans[keyPost].id,
        }, _this = this;

        request(
            `loan/file`,
            'DELETE',
            data,
            {
                "YT-AUTH-TOKEN": `YourTar ${this.props.token}`
            },
            function (response) {
                let tmp = _this.state.loans;
                delete tmp[keyPost].docs[keyDoc];
                _this.setState({loans: tmp, updated: Date.now()});
            },
            function (err) {
                console.log(err)
            }
        )
    };

    changeUserConfirm(key, checked) {
        const _this = this;
        let data = new FormData();
        data.append("user", this.state.loans[key].user.id);
        data.append("is_confirmed", checked ? 1 : 0);

        request(
            `user/edit`,
            "POST",
            data,
            {
                "YT-AUTH-TOKEN": `YourTar ${_this.props.token}`,
            },
            function (response) {
                let arr = Array.from(_this.state.loans);
                arr = arr.map(item => {
                    if (_this.state.loans[key].user.id === item.user.id) {
                        item.user.is_confirmed = !item.user.is_confirmed;
                    }
                    return item;
                })

                _this.setState({loans: arr, updated: Date.now()});
            }
        );
    }

    render() {
        const {loans} = this.state;
        return (
            <div className="container-fluid">
                <h4 className="row p-3 mb-0">Управление заявками на займ</h4>
                <div className='row table-responsive mb-2'>
                    <table className="col table">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th className='text-left'>Пользователь</th>
                            <th className='text-left'>Описание</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {loans.map((post, index) => {
                            let date = post.moment.split('T')[0].split('-')
                            return <tr key={post.id + '_' + this.state.updated}>
                                <td className="align-middle">{index + 1}</td>
                                <td className="align-middle text-left">
                                    {post.user.fio}
                                    <br/>
                                    {post.user.username && <>
                                        <a href={'tel:' + post.user.username}>{post.user.username}</a><br/></>}
                                    {post.user.email && <>
                                        <a href={'tel:+7' + post.user.email}>+7{post.user.email}</a><br/></>}
                                    <br/>
                                    Дата изменения: {(date[2] ?? 'xx').toString()}.
                                    {(date[1] ?? 'xx').toString()}.{(date[0] ?? 'xxxx').toString()}
                                    {post.passport && post.passport.length > 0 && <div>
                                        <p>Паспорт:</p>
                                        <ModalImage large={serverImages + post.passport}
                                                    small={serverImages + post.passport}
                                                    className={'imageRow smallImage'} alt={'Паcпорт'}/>
                                    </div>}
                                    <br/>
                                    <br/>
                                    <div className={'form-check form-switch'}>
                                        <input type={'checkbox'} defaultChecked={!!post.user.isConfirmed} onChange={(e) => this.changeUserConfirm(index, e.target.checked)}
                                               className={'form-check-input'} role="switch" id={"flexSwitchCheckDefault_" + post.id} />
                                        <label className="form-check-label" htmlFor={"flexSwitchCheckDefault_" + post.id}>Подтвержден</label>
                                    </div>
                                    <hr/>
                                    {post.office && <p>Выбранный офис: {post.office.city ? post.office.city + ', ' : ''} {post.office.address}</p>}
                                </td>
                                <td className="align-middle text-left">
                                    {post.id ? <p>ID: {post.id}</p> : ''}
                                    {post.title ? <p>Заголовок: {post.title}</p> : ''}
                                    {post.description ? <p>Описание: {post.description}</p> : ''}
                                    {post.model ? <p>Залог: {post.model.brand.name + ' ' + post.model.name}</p> : ''}
                                    {post.mark && post.customModel ? <p>Залог: {post.mark + ' ' + post.customModel}</p> : ''}
                                    <p>Договор{post.customId ? ' ' + post.customId : ''}: {post.done !== null && post.done !== undefined ?
                                        <span className={`badge badge-${post.signed ? 'success' : 'danger'}`}>
                                        {post.signed ? 'Подписан' : 'Не подписан'}</span> :
                                        <span className={`badge badge-primary`}>Новая</span>
                                    }</p>
                                    <div className="form-group">
                                    <p>Статус заявки:
                                        {
                                            post.status === 'pending' || post.status === null
                                            ? ' На рассмотрении' : post.status === 'rejected'
                                            ? ' Отказано' : post.status === 'approved'
                                            ? ' Одобрено' : post.status === 'transfer_made'
                                            ? ' Произведена выдача' : post.status === 'in_office'
                                            ? ' Выдача в офисе' : post.status === 'need_doc'
                                            ? ' Требуется загрузка документов' : post.status === 'online'
                                            ? ' Выдача онлайн' : post.status === 'finished'
                                            ? ' Завершена' : ' Подписана'
                                        }
                                    </p>
                                </div>
                                </td>
                                <td className="d-flex flex-column">
                                    <div className='float-right'>
                                        {includes(this.props.user.roles, 'ROLE_ADMIN') && <div className="form-group">
                                            <label htmlFor={post.id + "_exampleFormControlSelect"}>Выбор
                                                ответственного</label>
                                            <select className="form-control" id={post.id + "_exampleFormControlSelect"}
                                                    onChange={(e) => {
                                                        this.setManagers(e.target.value, index);
                                                    }}
                                                    defaultValue={post.manager ? post.manager.id : null}>
                                                <option value={null}>Не назначен</option>
                                                {this.state.managers.map((manager, key) => {
                                                    return <option key={key} value={manager.id}>{manager.fio}</option>;
                                                })}
                                            </select>
                                        </div>}
                                    </div>
                                    <button
                                        className='btn btn-success '
                                        onClick={() => {
                                            this.setState({isShow: true})
                                            this.setState({currentLoan: {...post}})
                                            this.setState({currentLoanIndex: index})
                                        }}
                                    >
                                        Показать подробную информацию
                                    </button>
                                </td>
                            </tr>
                        })}
                        {this.state.isShow &&
                            <LoanModal
                                isShow={this.state.isShow}
                                onHideModal={this.onHideModal}
                                data={this.state.loans[this.state.currentLoanIndex]}
                                index={this.state.currentLoanIndex}
                                user={this.props.user}
                                updated={this.state.updated}
                                handleAllowedAmount={this.handleAllowedAmount}
                                customizeLoan={this.customizeLoan}
                                handleLoanMessage={this.handleLoanMessage}
                                customizeLoanMessage={this.customizeLoanMessage}
                                handleLoanContract={this.handleLoanContract}
                                saveCustomLoanId={this.saveCustomLoanId}
                                doneLoan={this.doneLoan}
                                downloadFiles={this.downloadFiles}
                                removeDoc={this.removeDoc}
                                deleteLoan={this.deleteLoan}
                            />
                        }
                        </tbody>
                    </table>
                    <input type="file" style={{display: 'none'}} multiple={true}
                           ref={this.hiddenFileInput}
                           onChange={(event) => this.doneLoan(event, this.state.currentId, 1)}/>
                </div>
                <button onClick={() => this.getNews()} className={'btn btn-primary'}>Загрузить еще</button>
            </div>
        );
    }
}

export default connect(state => ({
    token: state.token,
    user: state.user,
}), dispatch => ({}))(LoanOrders);
