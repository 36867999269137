import React, {Component} from "react";
import {connect} from "react-redux";

import request from "../../services/ajaxManager";
import {formatData} from "../../services/utils";

const roles = [
    {value: "ROLE_MANAGER", label: "Менеджер"},
    {value: "ROLE_ADMIN", label: "Администратор"},
];

class Users extends Component {
    state = {
        page: 1,
        users: [],
        pages: 1,
        editedIndex: null,
        search: "",
        edited: {
            fio: "",
            phone: "",
            email: "",
            is_confirmed: false,
            is_blocked: false,
        },
        userDeleteIndex: '',
    };

    componentDidMount() {
        this.getUsers();
    }

    getUsers = () => {
        const {page} = this.state;
        const _this = this;

        request(
            `user/list?page=${page}${
                this.state.search.length > 0 ? "&search=" + this.state.search : ""
            }`,
            "GET",
            {},
            {
                "YT-AUTH-TOKEN": `YourTar ${_this.props.token}`,
            },
            function (response) {
                _this.setState({
                    users: response.body ?? response.users,
                    pages: response.count,
                });
            }
        );
    };
    changeRole = (userIndex, role, add) => {
        const _this = this;

        let data = new FormData();
        data.append("user", this.state.users[userIndex].id);
        data.append("role", role);
        data.append("type", add ? "add" : "remove");

        request(
            `user/role`,
            "POST",
            data,
            {
                "YT-AUTH-TOKEN": `YourTar ${_this.props.token}`,
            },
            function (response) {
                let tmp = _this.state.users;
                tmp[userIndex] = response.body ?? response;
                _this.setState({
                    users: tmp,
                });
            }
        );
    };
    editUser = (userIndex, check) => {
        const _this = this;
        let data = new FormData();
        data.append("user", this.state.users[userIndex].id);
        data.append("phone", this.state.edited.phone);
        data.append("email", this.state.edited.email);
        data.append("fio", this.state.edited.fio);
        data.append("is_confirmed", this.state.edited.is_confirmed ? 1 : 0);
        data.append("is_blocked", this.state.edited.is_blocked ? true : false);

        request(
            `user/edit`,
            "POST",
            data,
            {
                "YT-AUTH-TOKEN": `YourTar ${_this.props.token}`,
            },
            function (response) {
                let tmp = _this.state.users;
                let tmpForIs_confirmed = _this.state.edited;
                tmpForIs_confirmed[userIndex] = response.body ?? response;
                tmp[userIndex] = response.body ?? response;
                _this.setState({
                    users: tmp,
                    editedIndex: null,
                    edited: {
                        fio: "",
                        email: "",
                        phone: "",
                        is_confirmed: tmpForIs_confirmed.is_confirmed,
                        is_blocked: tmp.is_blocked,
                    },
                });
            }
        );

        request(
            `user/block`,
            'POST',
            {
                'id': this.state.users[userIndex].id,
                'is_blocked': this.state.edited.is_blocked
            },
            {
                "YT-AUTH-TOKEN": `YourTar ${_this.props.token}`
            },
            function (response) {
                let tmp = _this.state.users;

                let tmpForIs_blocked = _this.state.edited;
                tmpForIs_blocked[userIndex] = response.body ?? response;

                tmp[userIndex] = response.body ?? response;

                _this.setState({
                    users: tmp,
                    editedIndex: null,
                    edited: {
                        fio: "",
                        email: "",
                        phone: "",
                        is_confirmed: tmp.is_confirmed,
                        is_blocked: tmpForIs_blocked.is_blocked,
                    },
                });
            }
        )
    };

    deleteUser = (userIndex) => {
        const _this = this;

        request(
            'user/',
            'DELETE',
            {
                'id': this.state.users[userIndex].id
            },
            {
                "YT-AUTH-TOKEN": `YourTar ${this.props.token}`
            },
            function (response) {
                _this.getUsers();
            }
        )
    }

    render() {
        let pages = [];
        for (let i = 0; i < this.state.pages; i++) {
            pages.push(
                <li key={i}
                    className={"page-item" + (i + 1 === this.state.page && " active")}>
                    <a className="page-link"
                        href="#"
                        onClick={() => {
                            this.setState({page: i + 1}, this.getUsers);
                        }}>
                        {i + 1}
                    </a>
                </li>
            );
        }

        return (
            <div className="container-fluid">
                <h4 className="row p-3 mb-0">Управление пользователями</h4>
                <div className="row table-responsive mb-2">
                    <div>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">Поиск</div>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                onChange={(e) => {
                                    this.setState({search: e.target.value}, this.getUsers);
                                }}
                            />
                        </div>
                    </div>
                    <table className="col table">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th className="text-left">Пользователь</th>
                            <th className="text-left">Роли</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.users.map((user, index) => {
                            if (index === this.state.editedIndex) {
                                return (
                                    <tr key={user.id}>
                                        <td className="align-middle">{index + 1}</td>
                                        <td className="align-middle text-left">
                                            <div className="form-group">
                                                <label htmlFor={user.id + "_exampleInputFIO"}>
                                                    ФИО
                                                </label>
                                                <input
                                                    onChange={(e) => {
                                                        let tmp = this.state.edited;
                                                        tmp.fio = e.target.value;
                                                        this.setState({edited: tmp});
                                                    }}
                                                    className="form-control"
                                                    id={user.id + "_}exampleInputFIO"}
                                                    defaultValue={this.state.edited.fio}
                                                />
                                            </div>
                                        </td>
                                        <td className="align-middle text-left">
                                            <div className="form-group">
                                                <label htmlFor={user.id + "_}exampleInputPhone"}>
                                                    Телефон
                                                </label>
                                                <input
                                                    onChange={(e) => {
                                                        let tmp = this.state.edited;
                                                        tmp.phone = e.target.value;
                                                        this.setState({edited: tmp});
                                                    }}
                                                    className="form-control"
                                                    id={user.id + "_exampleInputPhone"}
                                                    defaultValue={this.state.edited.phone}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor={user.id + "_exampleInputMail"}>
                                                    E-mail
                                                </label>
                                                <input
                                                    onChange={(e) => {
                                                        let tmp = this.state.edited;
                                                        tmp.email = e.target.value;
                                                        this.setState({edited: tmp});
                                                    }}
                                                    className="form-control"
                                                    id={user.id + "_exampleInputMail"}
                                                    defaultValue={this.state.edited.email}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor={user.id + "_checkboxIsConfirmed"}>
                                                    Подтверждение пользователя
                                                </label>
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => {
                                                        let tmp = this.state.edited;
                                                        tmp.is_confirmed = e.target.checked;
                                                        this.setState({edited: tmp});
                                                    }}
                                                    className="form-control"
                                                    id={user.id + "_checkboxIsConfirmed"}
                                                    defaultChecked={
                                                        this.state.edited.is_confirmed ? 1 : 0
                                                    }
                                                />
                                                <label htmlFor={user.id + "_checkboxIsBlocked"}>
                                                    Блокировка пользователя
                                                </label>
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => {
                                                        let tmp = this.state.edited;
                                                        tmp.is_blocked = e.target.checked;
                                                        this.setState({edited: tmp});
                                                    }}
                                                    className="form-control"
                                                    id={user.id + "_checkboxIsBlocked"}
                                                    defaultChecked={
                                                        this.state.edited.is_blocked ? true : false
                                                    }
                                                />
                                            </div>
                                        </td>
                                        <td className="align-middle">
                                            <div className="float-right">
                                                <button
                                                    className="btn btn-success mr-3"
                                                    onClick={(e) => {
                                                        this.editUser(index);
                                                    }}
                                                >
                                                    Сохранить
                                                </button>
                                                <button
                                                    className="btn btn-danger mr-3"
                                                    onClick={() =>
                                                        this.setState({
                                                            editedIndex: null,
                                                            edited: {
                                                                fio: "",
                                                                phone: "",
                                                                email: "",
                                                                is_confirmed: this.state.edited.is_confirmed,
                                                                is_blocked: this.state.edited.is_blocked,
                                                            },
                                                        })
                                                    }
                                                >
                                                    Отмена
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            }

                            return (
                                <tr key={user.id}>
                                    <td className="align-middle">{index + 1}</td>
                                    <td className="align-middle text-left d-flex justify-content-between">
                                        <p>
                                            <i>{user.fio}</i>
                                            <br/>
                                            <a href={"tel:" + user.email}>{user.email}</a>
                                            <br/>
                                            <a href={"mailto:" + user.email2}>{user.email2}</a>
                                        </p>
                                        <div className="align-middle d-flex justify-content-center align-items-center">
                                            {user.isConfirmed ?
                                                <p className="m-0 mr-5 text-left font-weight-bold text-success">Подтверждён</p> : null}
                                            {user.isBlocked ?
                                                <p className="m-0 mr-5 text-left font-weight-bold text-danger">Заблокирован</p> : null}
                                        </div>

                                    </td>
                                    <td className="align-middle text-left">
                                        {roles.map((role, key) => {
                                            return (
                                                <div key={key}>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id={user.id + "_exampleCheck" + key}
                                                        onChange={(e) => {
                                                            this.changeRole(
                                                                index,
                                                                role.value,
                                                                e.target.checked
                                                            );
                                                        }}
                                                        defaultChecked={(typeof user.roles === 'object' ? Object.values(user.roles) : user.roles).includes(role.value)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor={user.id + "_exampleCheck" + key}
                                                    >
                                                        {role.label}
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </td>
                                    <td className="align-middle">
                                        <div className="float-right">
                                            <button
                                                className="btn btn-success mr-3"
                                                onClick={() => {
                                                    this.setState({
                                                        editedIndex: index,
                                                        edited: {
                                                            fio: user.fio,
                                                            email: user.email2,
                                                            phone: user.email,
                                                            is_confirmed: user.isConfirmed,
                                                            is_blocked: user.isBlocked,
                                                        },
                                                    });
                                                }}
                                            >
                                                Редактировать
                                            </button>
                                            <button
                                                className="btn btn-danger mr-3"
                                                data-toggle="modal" data-target="#deleteUserModal"
                                                onClick={() => this.setState(prev => ({...prev, userDeleteIndex: index}))}
                                            >
                                                Удалить
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                    <div className="modal fade" id="deleteUserModal" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Удаление пользователя</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    Вы уверены, что хотите удалить данного пользователя?
                                    Отменить эту операцию будет невозможно!
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                    >
                                        Отмена
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={() => {
                                                this.deleteUser(this.state.userDeleteIndex);
                                        }}
                                        data-dismiss="modal"
                                    >
                                        Да
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav>
                        <ul className="justify-content-center pagination">{pages}</ul>
                    </nav>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        token: state.token,
    }),
    (dispatch) => ({})
)(Users);
