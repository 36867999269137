import React, {Component} from 'react';
import {connect} from "react-redux";
import request from "../../services/ajaxManager";

class EditNews extends Component {
    handleSubmit = e => {
        e.preventDefault();

        let _this = this;

        let data = {
            'id': this.props.match.params.postId,
            'title': this.titleInput.value,
            'content': this.contentInput.value,
        };

        request(
            'news/',
            'PUT',
            {...data},
            {
                "YT-AUTH-TOKEN": `YourTar ${_this.props.token}`
            },
            function () {
                _this.props.history.push('/menu/news')
            }
        )
    };

    render() {
        const { news: { title, content }} = this.props;
        return (
            <div className='container-fluid'>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-11 mx-auto text-center form'>
                    <h4 className="p-3 mb-0">Изменить новость</h4>
                    <div>
                        <form className="justify-content-center" onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <span>Заголовок</span>
                                <input
                                    className='form-control'
                                    defaultValue={title}
                                    name="title"
                                    ref={(input) => {this.titleInput = input}}
                                />
                            </div>
                            <div className="form-group">
                                <span>Содержание</span>
                                <input
                                    className='form-control'
                                    defaultValue={content}
                                    name="content"
                                    ref={(input) => {this.contentInput = input}}
                                />
                            </div>
                            <button type='submit' className="btn btn-success">Изменить</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect (state => ({
    news: state.data.newsToEdit,
    token: state.token,
})) (EditNews);
