import React, {Component} from 'react';
import { connect } from 'react-redux';

import request from "../../services/ajaxManager";
import {formatData} from "../../services/utils";

class News extends Component {
    state = {
        page: 0,
    };

    componentDidMount() {
        this.getNews();
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight) {
            this.getNews();
        }
    };

    editNews = (post) => {
        this.props.editNews(post);
        this.props.history.push(`/menu/news/${post.id}`)
    };

    addNews = () => {
        this.props.history.push('/menu/news/create')
    };

    deleteNews = (id) => {
        const _this = this;

        request(
            'news/',
            'DELETE',
            {
                'id': id,
            },
            {
                "YT-AUTH-TOKEN": `YourTar ${this.props.token}`
            },
            function (response) {
                _this.getNews();
            }
        )
    };

    getNews = () => {
        const {page} = this.state;
        const _this = this;

        request(
            `news/${page === 0 ? '' : '?page=' + page}`,
            'GET',
            {},
            {},
            function (response) {
                _this.props.getNews(JSON.parse(response));
                _this.setState(prevState => ({
                    ...prevState,
                    page: prevState.page + 1
                }));

            }
        )
    };

    render() {
        const { news } = this.props;
        return (
            <div className="container-fluid">
                <h4 className="row p-3 mb-0">Управление новостями</h4>
                <div className='row table-responsive mb-2'>
                    <table className="col table">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th className='text-left'>Название</th>
                            <th className='text-left'>Содержание</th>
                            <th className='text-left'>Дата</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {news.map((post, index) => <tr key={index+1}>
                            <td className="align-middle">{index+1}</td>
                            <td className="align-middle text-left">{post.title}</td>
                            <td className="align-middle text-left">{post.content}</td>
                            <td className="align-middle text-left">{formatData(post.moment)}</td>
                            <td className="align-middle">
                                <div className='float-right'>
                                    <button className="btn btn-success mr-3" onClick={() => this.editNews(post)}>Редактировать</button>
                                    <button className="btn btn-danger" onClick={() => this.deleteNews(post.id)}>Удалить</button>
                                </div>
                            </td>
                        </tr>)}
                        </tbody>
                    </table>
                </div>
                <div className='row justify-content-end'>
                    <button className='btn btn-primary mr-3' onClick={()=> this.addNews()}>
                        Добавить новость
                    </button>
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    news: state.data.news,
    token: state.token,
}), dispatch => ({
    editNews: (newsData) => {
        dispatch({type: 'EDIT_NEWS', payload: newsData })
    },
    getNews: (newsData) => {
        dispatch({type: 'GET_NEWS_SUCCESS', payload: newsData})
    }
})) (News);
