/**
 * Created by misha on 27.01.19.
 */
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Index from './index';
import Menu from '../menu';
import LoginForm from './sign_action/login';
import RegForm from './sign_action/register';

class PublicLayout extends Component {
    componentDidMount() {
        const {token, history} = this.props;
        if(token !== false)
            history.push('/menu');
    }

    render() {
        return (
            <div>
                <Menu enabled={false}/>
                <Switch>
                    <Route exact path="/login" component={LoginForm}/>
                    <Route exact path="/" component={LoginForm}/>
                </Switch>
            </div>
        );
    }
}

export default withRouter(connect(
    (state, ownProps) => ({
        token: state.token,
    }),
    dispatch => ({})
)(PublicLayout));
