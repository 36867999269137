import React, {Component, useState} from 'react';
import request from "../../services/ajaxManager";
import {connect} from "react-redux";
import {formatData} from "../../services/utils";

const initialState = {
    messages: [],
    page: 0,
    isOpen: false,
};

const sortListValues = [
    'От ранних к поздним',
    'От поздних к ранним',
    'Сначала отвеченные',
    'Сначала неотвеченные'
]

const TableRow = ({ message: { question, answer, moment, id}, index, addAnswer, deleteMessage}) => {
    const [isEdit, setIsEdit] = useState(false);
    const [answerInput, setAnswer] = useState('');

    return (<tr>
        <td className="align-middle">{index+1}</td>
        <td className="align-middle text-left">{question}</td>
        <td className="align-middle text-left">{isEdit ?
            <input
                className='form-control'
                value={answerInput}
                onChange={e => setAnswer(e.target.value)}
            /> :
            !answer ? 'Не отвечен' : 'Отвечен'
        }
        </td>
        <td className="align-middle text-left">{formatData(moment)}</td>
        <td className="align-middle">
            {isEdit ?
                <div className='float-right'>
                    <button
                        className="btn btn-success mr-3"
                        onClick={() => {addAnswer(id, answerInput); setIsEdit(false)}}
                        disabled={answer}
                    >
                        Сохранить
                    </button>
                    <button
                        className="btn btn-danger"
                        onClick={() => {setIsEdit(false); setAnswer('')}}
                    >
                        Отмена
                    </button>
                </div> :
                <div  className='float-right'>
                    <button
                        className="btn btn-success mr-3"
                        onClick={() => setIsEdit(true)}
                        disabled={answer}
                    >
                        Ответить
                    </button>
                    <button
                        className="btn btn-danger"
                        onClick={() => deleteMessage(id)}
                    >
                        Удалить
                    </button>
                </div>
            }
        </td>
    </tr>)
};

class Messages extends Component {
    state = initialState;

    componentDidMount() {
        this.getMessages();
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight) {
            this.getMessages();
        }
    };

    toggleSortList= () => {
        this.setState(prevState => ({
            ...prevState,
            isOpen: !this.state.isOpen,
        }))
    }

    getMessages = () => {
        const {page} = this.state;
        const _this = this;

        request(
            `question/${page === 0 ? '' : '?page=' + page}`,
            'GET',
            {},
            {
                "YT-AUTH-TOKEN": `YourTar ${this.props.token}`
            },
            function (response) {
                if((response.body ?? response).length > 0) {
                    let tmp = response.body ?? response;
                    _this.setState(prevState => ({
                        ...prevState,
                        messages: [...prevState.messages, ...tmp ],
                        page: prevState.page + 1,
                    }))
                }
            }
        )
    };

    getSortedMessages = (sortIndex) => {
        const _this = this;
        let url = `question/`
        const sortIndexInt = parseInt(sortIndex)
    
        switch (sortIndexInt) {
            case 1:
                url = url.concat(`?moment=ASC`)
                break
            case 2:
                url = url.concat(`?moment=DESC`)
                break
            case 3:
                url = url.concat(`?answer=nlast`)
                break
            case 4:
                url = url.concat(`?answer=nfirst`)
                break
            default:
                console.log('Неизвестное значение');
                break
        }

        request(
            url,
            'GET',
            {},
            {
                "YT-AUTH-TOKEN": `YourTar ${this.props.token}`
            },
            function (response) {
                if((response.body ?? response).length > 0) {
                    let tmp = response.body ?? response;
                    _this.setState({
                        messages: [...tmp]
                    })
                }
            }
        )
    };

    deleteMessage = (id) => {
        const _this = this;

        request(
            'question/',
            'DELETE',
            {
                'id': this.state.messages[id].id,
            },
            {
                "YT-AUTH-TOKEN": `YourTar ${_this.props.token}`
            },
            function (response) {
                let arr = _this.state.messages;
                arr.splice(id, 1);
                _this.setState({messages: arr});
                _this.getMessages();
            }
        )
    };

    addAnswer = (id, answer) => {
        const _this = this;

        let data = new FormData();
        data.append('id', id);
        data.append('answer', answer);

        request(
            'question/answer',
            'POST',
            data,
            {
                "YT-AUTH-TOKEN": `YourTar ${_this.props.token}`
            },
            function () {
                let arr = Array.from(_this.state.messages);
                arr[arr.findIndex(mess => mess.id === id)].answer = answer;
                _this.setState({messages: arr});
            }
        )
    };

    render() {
        const { messages } = this.state;
        return (
            <div className='container-fluid'>
                <h4 className="row p-3 mb-0">Управление сообщениями поддержки</h4>
                <div className='p-1 mb-2 d-flex align-items-start'>
                    <h6 className='mr-3'>Сортировка сообщений:</h6>
                    <select onChange={(event) => this.getSortedMessages(event.target.value)}>
                        <option disabled selected>Выберите сортировку</option>
                        {sortListValues.map((item, index) => (
                            <option 
                                key={index}
                                value={index + 1}
                            >
                                {item}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='row table-responsive'>
                    <table className='col table'>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th className='text-left'>Вопрос</th>
                            <th className='text-left'>Статус</th>
                            <th className='text-left'>Дата</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {messages.map((message, index) =>
                            <TableRow
                                key={index}
                                message={message}
                                index={index}
                                addAnswer={this.addAnswer}
                                deleteMessage={() => this.deleteMessage(index)}
                            />)}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    token: state.token,
})) (Messages);
