import React from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {includes} from "../../services/utils";

const StaticInfo = (props) => {
	return (
		<div className='container-fluid'>
			<h4 className='row mt-3 mb-3 ml-1'>Статическая информация</h4>
			<ul className='row list-group'>
				{includes(props.user.roles, 'ROLE_ADMIN') &&
					<li className='list-group-item text-left'>
						<Link to='/menu/static/info'>
							Информационные статьи
						</Link>
					</li>
				}
				{includes(props.user.roles, 'ROLE_ADMIN') &&
					<li className='list-group-item text-left'>
						<Link to='/menu/static/payment'>
							Страница оплаты
						</Link>
					</li>
				}
			</ul>
		</div>
	)
};

export default connect(state => ({
    token: state.token,
    user: state.user,
}), dispatch => ({}))(StaticInfo);
