import React, {Component} from 'react';
import {connect} from "react-redux";
import request from "../../services/ajaxManager";
import Phone from "./phone";

class EditOffice extends Component {
    state = {
        phones: this.props.office.phones
    };

    addOffice = e => {
        e.preventDefault();

        const _this = this;

        let data = new FormData();
        data.append('city', this.cityInput.value);
        data.append('address', this.addressInput.value);
        data.append('phones', this.state.phones);
        data.append('email', this.emailInput.value);
        data.append('work_time', this.workInput.value);

        request(
            'office/',
            'POST',
            data,
            {
                "YT-AUTH-TOKEN": `YourTar ${this.props.token}`
            },
            function () {
                _this.props.history.push('/menu/offices')
            }
        )
    };

    editOffice = e => {
        e.preventDefault();

        const _this = this;

        const data = {
            'id': this.props.match.params.officeId,
            'city': this.cityInput.value,
            'work_time': this.workInput.value,
            'address': this.addressInput.value,
            'phones': this.state.phones,
            'email': this.emailInput.value,
        };

        request(
            'office/',
            'PUT',
            data,
            {
                "YT-AUTH-TOKEN": `YourTar ${this.props.token}`
            },
            function () {
                _this.props.history.push('/menu/offices')
            }
        )
    };

    addPhone = () => {
        this.setState(prevState => ({
            phones: [...prevState.phones, '']
        }), () => console.log(this.state));
    };

    changePhone = (phone, index) => {
        let tmp = [...this.state.phones]
        tmp.splice(index, 1, phone)
        this.setState({phones: tmp})
    };

    deletePhone = index => {
      this.setState(prevState => ({
          phones: [...prevState.phones.filter((item, indexItem) => index !== indexItem)]
      }));
    };

    createMode = () => {
        return this.props.match.params.officeId === 'create';
    };

    render() {
        const { office: { city, address, email, work_time } } = this.props;
        const { phones } = this.state;
        return (
            <div className='container-fluid'>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-11 mx-auto text-center form'>
                    <h4 className="p-3 mb-0">
                        {this.createMode() ? 'Добавить офис' : 'Изменить офис'}
                    </h4>
                    <form
                        className="justify-content-center"
                        onSubmit={
                            this.createMode() ? this.addOffice : this.editOffice
                        }
                    >
                        <div className="form-group">
                            <span>Город</span>
                            <input
                                className='form-control'
                                defaultValue={city}
                                name="city"
                                ref={(input) => {this.cityInput = input}}
                            />
                        </div>
                        <div className="form-group">
                            <span>Адресс</span>
                            <input
                                className='form-control'
                                defaultValue={address}
                                name="title"
                                ref={(input) => {this.addressInput = input}}
                            />
                            {address !== '' &&
                            <a
                                href={`https://www.google.ru/maps/dir/${this.props.office.city}, ${this.props.office.address}`}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                Открыть на карте
                            </a>}
                        </div>
                        <div className="form-group">
                            <span>E-mail</span>
                            <input
                                className='form-control'
                                defaultValue={email}
                                name="content"
                                ref={(input) => {this.emailInput = input}}
                            />
                        </div>
                        <div className="form-group">
                            <span>Режим работы</span>
                            <textarea
                                className='form-control'
                                defaultValue={work_time}
                                name="work"
                                ref={(input) => {this.workInput = input}}
                            />
                        </div>
                        <div className='form-group'>
                            <div>
                                <span className='p-2'>Телефоны</span>
                            </div>
                            {phones.length > 0 &&
                                <div>
                                    <div className='d-flex align-items-start'>
                                        <button
                                            type='button'
                                            className='btn btn-secondary mb-3'
                                            onClick={() => this.addPhone()}
                                        >
                                            Добавить
                                        </button>
                                    </div>
                                    {phones.map((phone, index) =>
                                        <Phone
                                            key={index}
                                            index={index}
                                            phone={phone}
                                            changePhone={this.changePhone}
                                            deletePhone={this.deletePhone}
                                        />)
                                    }
                                </div>
                            }
                        </div>
                        <button type='submit' className="btn btn-success">
                            {this.createMode() ? 'Добавить' : 'Сохранить'}
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

export default connect (state => ({
    office: state.data.officeToEdit,
    isEdit: state.data.isEdit,
    token: state.token
})) (EditOffice);
