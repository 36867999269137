import React, {Component} from 'react';
import {connect} from "react-redux";
import request from "../../services/ajaxManager";
import login from "../public/sign_action/login";

const initialState = {
    offices: [],
};

class Offices extends Component {
    state = initialState;

    componentDidMount() {
        this.getOffices();
    }

    editOffice = (office) => {
        this.props.editOffice(office);
        this.props.history.push(`/menu/offices/${office.id}`)
    };

    deleteOffice = (id) => {
        const _this = this;

        request(
            'office/',
            'DELETE',
            {
                'id': id
            },
            {
                "YT-AUTH-TOKEN": `YourTar ${this.props.token}`
            },
            function () {
                _this.getOffices();
            }
        )
    };

    addOffice = () => {
        this.props.editOffice({address: '', email: '', phones: []});
        this.props.history.push('/menu/offices/create')
    };

    getOffices = () => {
        const _this = this;
        request(
            'office/',
            'GET',
            {},
            {
                "YT-AUTH-TOKEN": `YourTar ${this.props.token}`
            },
            function (response) {
                _this.setState({
                    offices: response.body ?? response
                })
            }
        )
    };

    render() {
        const {offices} = this.state;
        return (
            <div className='container-fluid'>
                <h4 className='row p-3 mb-0'>Управление офисами</h4>
                <div className='row table-responsive'>
                    <table className='col table'>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th className='text-left'>Адрес</th>
                            <th className='text-left'>E-mail</th>
                            <th className='text-left'>Телефоны</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {offices.map((office, index) => <tr key={index + 1}>
                            <td className="align-middle">{index + 1}</td>
                            <td className="align-middle text-left">г. {office.city}, {office.address}</td>
                            <td className="align-middle text-left">{office.email}</td>
                            <td className="align-middle text-left">
                                {office.phones.map((phone, indexPh) => <div key={indexPh}>{phone}</div>)}
                            </td>
                            <td className="align-middle">
                                <div className='float-right'>
                                    <button
                                        className="btn btn-success mr-3"
                                        onClick={() => this.editOffice(office)}
                                    >
                                        Изменить
                                    </button>
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => this.deleteOffice(office.id)}
                                    >
                                        Удалить
                                    </button>
                                </div>
                            </td>
                        </tr>)}
                        </tbody>
                    </table>
                </div>
                <div className='row justify-content-end'>
                    <button className='btn btn-primary mr-3' onClick={() => this.addOffice()}>
                        Добавить офис
                    </button>
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    token: state.token,
}), dispatch => ({
    editOffice: (officeData) => {
        dispatch({type: 'EDIT_OFFICE', payload: officeData})
    }
}))(Offices);
