import React, {Component} from 'react';
import {connect} from "react-redux";
import request from "../../services/ajaxManager";

const initialState = {
    curError: '',
    newError: '',
    repError: '',
    matchError: '',
};

class ChangePassword extends Component {
    state = initialState;

    handleSubmit = e => {
        e.preventDefault();

        const _this = this;

        let data = {
            'current_pass': this.currentPasswordInput.value,
            'new_pass': this.newPasswordInput.value,
            'confirm_pass': this.repeatPasswordInput.value
        };

        this.validatePassword(data).then(()=> {
            let validationData = {...this.state};
            let errorsCount = 0;

            Object.keys(validationData).forEach(item => {
                if(validationData[item] !== '')
                    errorsCount++;
            });

            if(errorsCount === 0) {
                request(
                    'user/change/password',
                    'POST',
                    {...data},
                    {
                        "YT-AUTH-TOKEN": `YourTar ${_this.props.token}`
                    },
                    function () {
                        _this.props.history.push('/menu');
                    }
                )
            }
        });



    };

    async validatePassword ({current_pass, new_pass, confirm_pass}) {
        if(this.isBlank(current_pass))
            this.setState(prevState => ({
                ...prevState,
                curError: 'Поле не может быть пустым'
            }));
        else
            this.setState(prevState => ({
                ...prevState,
                curError: ''
            }));

        if(this.isBlank(new_pass))
            this.setState(prevState => ({
                ...prevState,
                newError: 'Поле не может быть пустым'
            }));
        else
            this.setState(prevState => ({
                ...prevState,
                newError: ''
            }));

        if(this.isBlank(confirm_pass))
            this.setState(prevState => ({
                ...prevState,
                repError: 'Поле не может быть пустым'
            }));
        else
            this.setState(prevState => ({
                ...prevState,
                repError: ''
            }));

        if(new_pass !== confirm_pass)
            this.setState(prevState => ({
                ...prevState,
                matchError: 'Пароли не совпадают'
            }));
    };

    isBlank = str => {
        return str === '';
    };

    render() {
        const { curError, newError, repError, matchError } = this.state;
        return (
            <div className='container-fluid'>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-11 mx-auto text-center form'>
                    <h4 className="p-3 mb-0">Изменить пароль</h4>
                    <div>
                        <form className="justify-content-center" onSubmit={this.handleSubmit}>
                            <div className='form-group'>
                                <span>Текущий пароль</span>
                                <input
                                    className='form-control mb-1'
                                    name='currentPassword'
                                    type='password'
                                    ref={input => {this.currentPasswordInput = input}}
                                />
                                <span className='err'>{curError}</span>
                            </div>
                            <div className='form-group'>
                                <span>Новый пароль</span>
                                <input
                                    className='form-control mb-1'
                                    name='newPassword'
                                    type='password'
                                    ref={input => {this.newPasswordInput = input}}
                                />
                                <span className='err'>{newError}</span>
                            </div>
                            <div className='form-group'>
                                <span>Повторите пароль</span>
                                <input
                                    className='form-control mb-1'
                                    name='repeatPassword'
                                    type='password'
                                    ref={input => {this.repeatPasswordInput = input}}
                                />
                                <span className='err'>{repError}</span>
                            </div>
                            <div className='err form-group'>{matchError}</div>
                            <button type='submit' className="btn btn-success">Отправить</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    token: state.token
})) (ChangePassword);
