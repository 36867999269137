/**
 * Created by misha on 27.01.19.
 */
import React from 'react';
import AbstractForm from '../../abstract/form';
import {connect} from 'react-redux';
import Logo from '../../../images/spfinans_logo.svg';

import request from "../../../services/ajaxManager";

class LoginForm extends AbstractForm {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);

        this.state = {
            isShowInputCode: false,
            phone: '',
            code: '',
            time: {},
            seconds: 60,
        }
    }

    secondsToTime(secs){
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };

        return obj;
    }

    startTimer() { console.log('try to start')
        if (this.timer == 0 && this.state.seconds > 0) {  console.log('STARTED')
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds == 0) {
            clearInterval(this.timer);
            this.timer = 0;
        }
    }

    handleSubmit(isCode) {
        let _this = this;

        const data = new FormData()

        if (isCode) {
            data.append('phone', this.state.phone)
            data.append('code', this.state.code)
        } else {
            data.append('phone', this.state.phone)
        }
        console.log(data);

        request(
            isCode ? 'security/sms/code' : 'security/sms',
            'POST',
            data,
            {},
            function (res) {
                if (isCode) {
                    _this.props.onAddUser(res.token);
                    _this.props.onAddUserData(res.body ?? res.user);
                    const {history} = _this.props;
                    history.push('/menu');
                } else {
                    let timeLeftVar = _this.secondsToTime(60);
                    _this.setState({isShowInputCode: true, seconds: 60, time: timeLeftVar}, () => _this.startTimer());

                }
            },
            function (err) {
                console.log(err)
            }
        )
    }

    viewForm() {
        return (
            <div className="container-fluid">
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-11 mx-auto text-center form p-4'>
                    <img alt='Logo' src={Logo}/>
                    <h4 className="p-3 mb-0">Вход</h4>
                    <div>
                        <input
                            name="phone"
                            type={"text"}
                            required={true}
                            value={this.state.phone}
                            placeholder={"Номер телефона"}
                            className='form-group form-control login-input'
                            onChange={(e) => this.setState({phone: e.target.value})}
                        />
                        <p className="text-center">
                            <button className="btn btn-success"
                                    onClick={(e) => this.handleSubmit(false)}>Выслать код
                            </button>
                        </p>
                        {
                            this.state.isShowInputCode
                            &&
                            <>
                                <input
                                    name="code"
                                    type={"number"}
                                    required={true}
                                    placeholder={"Код"}
                                    className='form-group form-control login-input'
                                    onChange={(e) => this.setState({code: e.target.value})}
                                />
                                <p className="text-center">
                                    <button className="btn btn-success"
                                            onClick={(e) => this.handleSubmit(true)}>Войти
                                    </button>
                                </p>
                                {this.state.seconds > 0 ? <p className={'text-center'}>
                                    Код действителен {this.state.time.m}:{this.state.time.s}
                                </p> : <div className={'btn btn-link'} onClick={() => this.handleSubmit(false)}>
                                    Выслать код повторно
                                </div>}
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        store: state,
    }),
    dispatch => ({
        onAddUser: (user) => {
            dispatch({type: 'ADD_TOKEN', payload: user})
        },
        onAddUserData: (user) => {
            dispatch({type: 'ADD_USER', payload: user})
        }
    })
)(LoginForm);
